<template>
  <div class="container_login">
    <Navbar/>
    <div class="container_login__container_form">
      <b-container class="h-100">
        <b-row align-h="center" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="4" align-self="center">
            <form @submit.prevent="sendForm">
              <b-card no-body class="py-5 px-4">
                <b-container>
                  <b-row>
                    <b-col>
                      <h3>Please login to get your subscription</h3>

                      <!-- Email -->
                      <b-form-group label="Email" label-for="email" class="text-start mb-3" :class="{'error-form-group': $v.form.email.$error}">
                        <b-form-input id="email" type="email" placeholder="Email" trim
                          :class="{'is-invalid': $v.form.email.$error}" v-model.trim="$v.form.email.$model"></b-form-input>

                        <span role="alert" class="invalid-feedback">
                          <span v-if="!$v.form.email.required">The Email is required</span>
                          <span v-if="!$v.form.email.email">Invalid Email format</span>
                          <span v-if="!$v.form.email.maxLength">
                            The Email must be less than {{$v.form.email.$params.maxLength.max}} letters
                          </span>
                        </span>
                      </b-form-group>

                      <!-- Password -->
                      <b-form-group label="Password" label-for="password" class="text-start mb-4" :class="{'error-form-group': $v.form.password.$error}">
                        <b-form-input id="password" type="password" placeholder="Password" trim
                          :class="{'is-invalid': $v.form.password.$error}" v-model.trim="$v.form.password.$model"></b-form-input>

                        <span role="alert" class="invalid-feedback" v-if="$v.form.password.$error">
                          <span v-if="!$v.form.password.required">The Password is required</span>
                          <span v-if="!$v.form.password.minLength">
                            The Password must have at least {{$v.form.password.$params.minLength.min}} characters
                          </span>
                          <span v-if="!$v.form.password.maxLength">
                            The Password must be less than {{$v.form.password.$params.maxLength.max}} characters
                          </span>
                        </span>
                      </b-form-group>

                      <!-- Recaptcha -->
                      <vue-recaptcha ref="recaptcha" sitekey="6LfZ-wYfAAAAAP7rkAPUU3RKiJixfyVmN4Ytz0TC" 
                        size="invisible" @verify="validateRecaptcha" @expired="validateRecaptcha">
                      </vue-recaptcha>
                      
                      <!-- Button -->
                      <b-button variant="primary" type="submit" class="mb-2 w-100">Login</b-button>

                      <!-- Forgot Password -->
                      <div class="w-100 text-end mb-4">
                        <router-link :to="{ name: 'password.reset' }" class="text-decoration-none forgot">Forgot your password?</router-link>
                      </div>

                      <!-- Links -->
                      <b-container class="p-0">
                        <b-row class="links">
                          <b-col>
                            <a href="#" class="text-decoration-none link-primary">Don’t have an account?</a>
                          </b-col>
                          <b-col class="text-end">
                            <router-link :to="{ name: 'register' }" class="text-decoration-none link-primary">Sign Up</router-link>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer/>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import {required, email, minLength, maxLength} from 'vuelidate/lib/validators';
import Navbar from '@/components/Auth/Navbar.vue';
import VueRecaptcha from 'vue-recaptcha';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Login',
  components: {
    Navbar,
    VueRecaptcha,
    Footer
  },
  data () {
    return {
      form: {
        email: String(),
        password: String(),
        recaptcha: null,
      },
      load: false,
    }
  },
  beforeCreate() {
    if (this.$session.exists()) {
      this.$router.push({ name: 'dashboard' });
    }
  },
  validations: {
    form: {
      email: { required, email, maxLength: maxLength(60) },
      password: { required, minLength: minLength(8), maxLength: maxLength(30) },
    },
  },
  methods: {
    sendForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.generateRecaptcha();
      }
    },
    validateRecaptcha(response){
      this.$refs.recaptcha.reset();
      this.form.recaptcha = response;

      this.$http.post('login', this.form).then((response) => {
        if (response.status === 200) {
          if (response.body.response === 'account_verification') {
            this.$notify({ group: 'notifications_home', text: response.body.message, type: 'warning' });
          }
          if (response.body.response === 'login_successful') {
            this.$session.start();
            this.$session.set('jwt', response.body.token);
            this.$session.set('userInfo', response.body.data);
            this.$router.push({ name: 'dashboard' });
          }
        }
        this.load = false;
      }, (error) => {
        if (error.status === 400) {
          this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
        } else if (error.status === 500) {
          this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
        }
        this.load = false;
      });
    },
    generateRecaptcha(){
      this.$refs.recaptcha.execute();
    },
  }
}
</script>
